<footer class="bg-blue color-white py-5 mt-5">
    <div class="container">
        <div class="row mb-4">
            <div class="col-12 col-md-6 mb-5 mb-md-0">
                <address class="mb-1">
                    <h1>Maler Maifarth</h1>
                    Obertor 14<br>
                    34587 Felsberg
                </address>
                <a class="unstyled maps-link" href="https://goo.gl/maps/WbmK3zJe8pMGcgqv9" target="_blank" rel="nofollow noopener">Auf Google Maps ansehen</a>
            </div>
            <div class="col-12 col-md-6">
                <h1>Kontakt</h1>
                <a class="unstyled" href="tel:+4956624681">Tel: +49 5662 4681</a><br>
                <a class="unstyled" href="fax:+4956626348">Fax: +49 5662 6348</a><br>
                <a class="unstyled" href="mailto:maler.maifarth@t-online.de">Mail: maler.maifarth@t-online.de</a>
            </div>
            <!-- <div class="col-12 col-md-6 col-lg-3 mb-4">
                <h1>Bereiche</h1>
                <a class="unstyled" routerLink="ueber-uns">Über uns</a><br>
                <a class="unstyled" routerLink="leistungen">Leistungen</a><br>
                <a class="unstyled" routerLink="denkmalpflege">Denkmalpflege</a>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-4">
                <h1>Rechtliches</h1>
                <a class="unstyled" routerLink="impressum">Impressum</a><br>
                <a class="unstyled" routerLink="datenschutz">Datenschutz</a>
            </div> -->
        </div>
        <div class="row mb-4">
            <div class="col">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <a class="unstyled" routerLink="impressum">Impressum</a><br>
                <!-- <a class="unstyled exportarts" href="https://www.exportarts.io" target="_blank" rel="noopener nofollow">
                    Made with
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18">
                        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                    </svg>
                    by Exportarts
                </a> -->
            </div>
        </div>
    </div>
</footer>
