import { Component } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';

@Component({
  selector: 'maif-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'maif-webpage';

  constructor(
    private readonly scully: IdleMonitorService
  ) {}
}
