<nav class="bg-white sticky-top" #nav>
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
                <a class="unstyled" routerLink="/">
                    <img src="/assets/logo_original.png" height="48" alt="">
                </a>
                <!-- <a routerLink="/ueber-uns" class="unstyled d-none d-lg-inline">Über uns</a>
                <a routerLink="/leistungen" class="unstyled d-none d-lg-inline">Leistungen</a>
                <a routerLink="/denkmalpflege" class="unstyled d-none d-lg-inline">Denkmalpflege</a> -->
                <a routerLink="/kontakt" class="maif-button d-inline">Kontakt</a>
                <!-- <span role="button" class="d-lg-none" (click)="toggle()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
                    </svg>
                </span> -->
            </div>
        </div>
    </div>
</nav>

<div class="extended-nav" [ngClass]="expanded ? 'd-flex flex-column align-items-center' : 'd-none'">
    <a routerLink="/ueber-uns" class="unstyled">Über uns</a>
    <a routerLink="/leistungen" class="unstyled">Leistungen</a>
    <a routerLink="/denkmalpflege" class="unstyled">Denkmalpflege</a>
    <a routerLink="/kontakt" class="maif-button d-md-none">Kontakt</a>
</div>
