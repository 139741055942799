import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FooterComponent } from './navigation/components/footer/footer.component';
import { NavbarComponent } from './navigation/components/navbar/navbar.component';

const routes: Routes = [
  {
    path: '',
    outlet: 'footer',
    component: FooterComponent
  },
  {
    path: '',
    outlet: 'navbar',
    component: NavbarComponent
  },
  {
    path: 'impressum',
    loadChildren: () => import('./impressum/impressum.module').then(m => m.ImpressumModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
