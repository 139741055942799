import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'maif-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {

  private readonly scrollClass = 'scrolled';

  @ViewChild('nav')
  nav: ElementRef<HTMLDivElement>;

  expanded = false;

  constructor() { }

  ngAfterViewInit() {
    // Make sure the nav has the correct classes.
    this.onScroll();
  }

  toggle() {
    this.expanded = !this.expanded;
    const body = document.getElementsByTagName('body').item(0);
    body.classList.toggle('overflow-hidden');
  }

  @HostListener('window:resize')
  onResize() {
    if (this.expanded && window.innerWidth > 992) {
      this.expanded = false;
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    const scroll = window.scrollY;
    if (scroll >= 50 && !this.nav.nativeElement.classList.contains(this.scrollClass)) {
      this.nav.nativeElement.classList.add(this.scrollClass);
    } else if (scroll < 50) {
      this.nav.nativeElement.classList.remove(this.scrollClass);
    }
  }

}
